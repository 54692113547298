<template>
    <div>
      <el-popover
        placement="top"
        width="160"
        v-model="visible"
      >
        <p>Вы уверены что хотите удалить кэш VectorTile слоев?</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="primary" @click="resetVTCaches">Да</el-button>
          <el-button size="mini" type="text" @click="visible = false">Нет</el-button>
        </div>
        <el-button slot="reference" type="danger" plain>Удалить кэш VectorTile слоев</el-button>
      </el-popover>
    </div>
</template>
<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { MapEditorAPI } from '@/core/infrastructure/api/modules/MapEditorAPI'

export default {
  name: 'ButtonResetVTCacheLayers',
  props: ['other-properties'],
  data: () => {
    return {
      visible: false
    }
  },
  methods: {
    async resetVTCaches () {
      console.log(this.otherProperties.layers)
      let count = await this.resetVTCachesByLayers(this.otherProperties.layers, 0)
      if (count === 0) {
        this.$message.warning('Слои векторных тайлов с источником реестр не найдены')
      } else {
        this.$message.success('Удален кэш ' + count + ' VectorTile слоев')
      }
    },
    async resetVTCachesByLayers (layers, count) {
      console.log(layers)
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i]
        if (layer.type === 'group') {
          count = await this.resetVTCachesByLayers(layer.children, count)
          continue
        }
        if (layer.properties.source.type === 'Registry' && layer.properties.source.isVectorTile) {
          await APIClient.shared.request(
            new MapEditorAPI.ResetVTLayerCacheByLayer(
              layer.properties.source.entityId,
              layer.properties.source.geometryField,
              layer.guid
            )
          )
          count++
        }
      }
      return count
    }
  }
}
</script>

<style scoped>
</style>
